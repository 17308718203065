<template>
  <span>
    <nav-bar>
      <v-main class="main" slot="main">
        <v-container class="py-8 px-6" fluid>
          <router-view v-if="!otpDialog"/>
          <v-dialog v-model="otpDialog" persistent max-width="300px">
            <v-card>
              <v-card-title>Enter Secret Code</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-otp-input length="6" type="number" v-model="pin"></v-otp-input>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </nav-bar>
  </span>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  data() {
    return {
      pin: 0,
      confirmOtp: "030201",
    };
  },
  components: {
    NavBar,
  },
  computed: {
    otpDialog() {
      return this.pin != this.confirmOtp;
    }
  },
};
</script>

<style scoped>
.main {
  background-color: #f1f1f1;
}
</style>