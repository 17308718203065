import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Tasks from "../views/Tasks.vue";
import TheCoins from "../views/TheCoins.vue";
Vue.use(VueRouter);

const routes = [

  {
    path: "/5924909533",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/3510832808",
    name: "Tasks",
    component: Tasks,
  },
  {
    path: "/8994150185",
    name: "TheCoins",
    component: TheCoins,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
