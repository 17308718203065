<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="mini"
      mini-variant-width="70"
      color="primary"
      app
      dense
    >
      <v-sheet class="pa-4" color="primary" v-if="!mini">
        <v-avatar class="mb-4" size="100">
          <v-img src=""> </v-img>
        </v-avatar>
        <div>{{  }}</div>
      </v-sheet>

      <v-list-item class="px-2" v-else>
        <v-list-item-avatar>
          <v-img src="">
          </v-img>
        </v-list-item-avatar>

        <v-list-item-title>
          {{  }}
        </v-list-item-title>

        <v-btn icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>
      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="[icon, text, toLink] in links"
          :key="icon"
          link
          :to="toLink"
          custom
          color="white"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title color="white">{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar app flat color="grey">
      <v-btn icon @click.stop="openDrawer" color="primary">
        <v-icon>
          {{ mini || miniDrawer ? "mdi-chevron-right" : "mdi-chevron-left" }}
        </v-icon>
      </v-btn>
    </v-app-bar>
    <slot name="main"></slot>
  </v-app>
</template>

<script>

export default {
  data: () => ({
    mini: true,
    miniDrawer: false,
    drawer: null,
    links: [
      ["mdi-format-list-checks", "Tasks", "/3510832808"],
      ["mdi-hand-coin-outline", "Coins", "/8994150185"],
    ],
  }),
  watch: {
    drawer(oldVal, newVal) {
      if (newVal == false) {
        this.mini = false;
        this.miniDrawer = true;
      }
    },
  },
  methods: {
    logoutUser() {
      this.logout();
      this.$router.push("/Home");
    },
    openDrawer() {
      this.drawer = true;
      this.mini = !this.mini;
    },
  },
  created() {},
  computed: {
    minidrawer() {
      return !this.drawer;
    },
  },
};
</script>

<style scoped>
.profile-image {
  background-image: url(../assets/Avatar.png);
  background-size: 110px 110px;
}
</style>