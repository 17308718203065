import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async getAllTasks() {
      try {
        var response = await axios
          .get(
            `/tasks/GetAll.php`
          )
        if (response.status == 200) {
          return response.data;
        }
        else
          return false
      } catch (error) {
        return false;
      }
    },
  async PostReward(context, obj) {
    try {
      var response = await axios
        .post(
          `/tasks/PostReward.php`, obj
        )
      if (response.status == 201) {
        return true;
      }
      else
        return false
    } catch (error) {
      return false;
    }
  },
  async PostReject(context, obj) {
    try {
      var response = await axios
        .post(
          `/tasks/PostReject.php`, obj
        )
      if (response.status == 201) {
        return true;
      }
      else
        return false
    } catch (error) {
      return false;
    }
  },
  async getCoinPayouts() {
    try {
      var response = await axios
        .get(
          `/Payout/GetAll.php`
        )
        if (response.status == 200) {
          return response.data;
        }
        else
          return false
    } catch (error) {
      return false;
    }
  },
  async RewardPayout(context, obj) {
    try {
      var response = await axios
        .post(
          `/Payout/PutStatus.php`, obj
        )
        if (response.status == 201) {
          return true;
        }
        else
          return false
    } catch (error) {
      return false;
    }
  },
},
});
