<template>
  <v-container>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="9">
        <h1 class="pa-5">Requests</h1>
        <v-alert dense outlined v-if="payouts.length == 0" type="error">
          No Requests available.
        </v-alert>
        <v-card>
          <v-row
            v-for="payout in payouts"
            :key="payout.Id"
            class="pa-5 d-flex align-center"
          >
            <v-col cols="2"> &#x20b9; {{ payout.Amount }} </v-col>
            <v-col cols="2">
              <b>UPI Id:</b> <br />
              {{ payout.UPIId }}
            </v-col>
            <v-col cols="2">
              <b>Requested On:</b> <br />
              {{ payout.CreatedAt.substring(0, 10) }}
            </v-col>
            <v-col cols="2">
              <b>SP Name:</b> <br />
              {{ payout.Name }}
            </v-col>
            <v-col cols="1">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="payout.Status == 0"
                    @click.native="rewardPayout(payout.Id)"
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                    style="cursor: pointer"
                  >
                    &nbsp;mdi-account-cash
                  </v-icon>
                </template>
                <span>Reward</span>
              </v-tooltip>
            </v-col>
            <v-col cols="3">
              <v-chip :color="getTTColor(payout.Status)">
                {{ getTTText(payout.Status) }}
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar top :color="alertType" v-model="snackbar">
      {{ alertMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  data: () => ({
    payouts: [],
    type: 1,
    amount: null,
    valid: true,
    alertType: "",
    snackbar: false,
    alertMessage: "",
  }),
  methods: {
    async rewardPayout(id) {
      if (
        await this.RewardPayout(
          JSON.stringify({
            Id: id,
          })
        )
      ) {
        this.alertMessage = "Payout Successfully!";
        this.alertType = "green";
        this.snackbar = true;
        this.init();
      } else {
        this.alertMessage = "Payout Failed!";
        this.alertType = "red";
        this.snackbar = true;
      }
    },
    ...mapActions(["getCoinPayouts", "RewardPayout"]),
    getTTColor(status) {
      switch (status) {
        case 0:
          return "red";
        case 1:
          return "green";
      }
    },
    getTTText(status) {
      switch (status) {
        case 0:
          return "Pending..";
        case 1:
          return "Confirmed!";
      }
    },
    async init() {
      this.payouts = await this.getCoinPayouts();
    },
  },
  computed: {},
  async created() {
    this.init();
  },
};
</script>