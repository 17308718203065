<template>
  <span>
    <v-card>
      <v-card-title>
        Tasks
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table :headers="headers" :items="allTasks" :search="search">
        <template v-slot:item.SessionUrl="{ item }">
          <a
            :href="item.SessionUrl"
            target="_blank"
            style="text-decoration: none"
            v-if="item.SessionUrl"
          >
            Open Link
            <v-icon color="primary" small> mdi-open-in-new </v-icon>
          </a>
        </template>
        <template v-slot:item.SessionImageUrl="{ item }">
          <v-img :src="item.SessionImageUrl" width="100" height="100"> </v-img>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="item.Status == 0"
            @click.native="taskReward(item.Id, item.StudentPartnerId)"
            color="green"
            style="cursor: pointer"
          >
            &nbsp;mdi-account-cash
          </v-icon>
          <v-icon
            v-if="item.Status == 0"
            @click.native="taskReject(item.Id, item.StudentPartnerId)"
            color="red"
            style="cursor: pointer"
          >
            &nbsp;mdi-close-octagon
          </v-icon>
        </template>
        <template v-slot:item.Status="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{  on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :color="getTTColor(item.Status)">
                mdi-checkbox-blank-circle
              </v-icon>
            </template>
            <span>{{ getTTText(item.Status) }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.Reward="{ item }">
          <span v-if="item.Reward">
            <b> {{ item.Reward }} </b>
            <v-icon color="orange"> mdi-link-variant </v-icon>
          </span>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="rejectDialog"
    >
      <template v-slot:default="rejectDialog">
        <v-card>
          <v-toolbar color="primary">Reject task</v-toolbar>
          <v-form
            ref="rejectform"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitReject"
          >
            <v-card-text>
              <v-text-field
                v-model="rejectMessage"
                label="Reason for rejection"
                solo
              ></v-text-field>
            </v-card-text>

            <v-card-actions class="justify-end">
              <v-btn text @click="rejectDialog.value = false">Close</v-btn>
              <v-btn
                class="mr-4"
                type="submit"
                :loading="isLoading"
                color="primary"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog
      transition="dialog-top-transition"
      max-width="600"
      v-model="rewardDialog"
    >
      <template v-slot:default="rewardDialog">
        <v-card>
          <v-toolbar color="primary">Reward task</v-toolbar>
          <v-form
            ref="rewardform"
            v-model="valid"
            lazy-validation
            @submit.prevent="submitReward"
          >
            <v-card-text>
              <v-text-field
                v-model="RewardedAmount"
                label="Amount"
                solo
              ></v-text-field>
            </v-card-text>

            <v-card-actions class="justify-end">
              <v-btn text @click="rewardDialog.value = false">Close</v-btn>
              <v-btn
                class="mr-4"
                type="submit"
                :loading="isLoading"
                color="primary"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </template>
    </v-dialog>

    <v-dialog v-model="loadingDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar top :color="alertType" v-model="snackbar">
      {{ alertMessage }}
    </v-snackbar>
  </span>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      valid: true,
      search: "",
      isLoading: false,
      activeTaskId: false,
      rejectMessage: "",
      RewardedAmount: null,
      alertMessage: "",
      alertType: "",
      snackbar: false,
      rejectDialog: false,
      rewardDialog: false,
      loadingDialog: false,
      allTasks: [],
      activeStudentPartnerId: null,
      headers: [
        {
          text: "Task Type",
          align: "start",
          sortable: false,
          value: "TaskTypeId",
        },
        { text: "Conducted On", value: "ConductedOn" },
        { text: "Description", value: "Message" },
        { text: "Url", value: "SessionUrl" },
        { text: "Image", value: "SessionImageUrl" },
        { text: "Reason", value: "Reason" },
        { text: "Status", value: "Status" },
        { text: "Reward", value: "CoinsRewarded" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {},
  methods: {
    ...mapActions(["getAllTasks", "PostReward", "PostReject"]),
    taskReject(id, studentPartnerId) {
      this.rejectDialog = true;
      this.activeTaskId = id;
      this.activeStudentPartnerId = studentPartnerId;
    },
    taskReward(id, studentPartnerId) {
      this.rewardDialog = true;
      this.activeTaskId = id;
      this.activeStudentPartnerId = studentPartnerId;
    },
    async submitReject() {
      var obj = JSON.stringify({
        TaskId: this.activeTaskId,
        StudentPartnerId: this.activeStudentPartnerId,
        Reason: this.rejectMessage,
        Status: 1,
      });
      if (await this.PostReject(obj)) {
        this.alertMessage = "Rejected Successfully!";
        this.alertType = "green";
        this.snackbar = true;
        this.$refs.rejectform.reset();
        this.rejectDialog = false;
        this.getAllAdminTasks();
      }
    },
    async submitReward() {
      var obj = JSON.stringify({
        TaskId: this.activeTaskId,
        StudentPartnerId: this.activeStudentPartnerId,
        CoinsRewarded: this.RewardedAmount,
        Status: 2,
      });
      if (await this.PostReward(obj)) {
        this.alertMessage = "Rewarded Successfully!";
        this.alertType = "green";
        this.snackbar = true;
        this.$refs.rewardform.reset();
        this.rewardDialog = false;
        this.getAllAdminTasks();
      }
    },
    getTTColor(status) {
      switch (status) {
        case 0:
          return "primary";
        case 1:
          return "red";
        case 2:
          return "green";
      }
    },
    getTTText(status) {
      switch (status) {
        case 0:
          return "Under Review";
        case 1:
          return "Rejected";
        case 2:
          return "Rewarded";
      }
    },

    resetForm() {
      this.taskType = "";
      this.conductedOn = "";
      this.sessionUrl = "";
      this.sessionImageUrl = "";
      this.message = "";
      this.sessionImage = null;
    },

    async getAllAdminTasks() {
      this.allTasks = await this.getAllTasks();
      this.loadingDialog = false;
    },
  },
  created() {
    this.loadingDialog = true;
    this.getAllAdminTasks();
  },
};
</script>